import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_page = _resolveComponent("standard-page")!
  const _component_error_page = _resolveComponent("error-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.data && !$setup.error)
      ? (_openBlock(), _createBlock(_component_standard_page, {
          key: 0,
          data: $setup.data.page,
          categories: $setup.data.categories,
          published: $setup.data.datePublished
        }, null, 8, ["data", "categories", "published"]))
      : _createCommentVNode("", true),
    ($setup.error)
      ? (_openBlock(), _createBlock(_component_error_page, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}