import { createRouter, createWebHistory } from 'vue-router';
import SiteView from '@/layouts/SiteView.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/:catchAll(.*)',
			name: 'page',
			component: SiteView,
		},
	],
	scrollBehavior(to, from) {
		if (to.path !== from.path) return { top: 0 };
	},
});

export default router;
