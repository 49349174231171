<template>
	<div class="page-loader flex-y-center flex-x-center">
		<div>
			<img
				src="@/assets/images/capo-logo-black.webp"
				alt="Capo AB"
				loading="eager"
			/>
		</div>
	</div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.page-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	color: #333;
	z-index: 9999;

	img {
		width: 200px;
		height: 75px;
	}
}
</style>
