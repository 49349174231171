import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styles/styles.scss';
import './assets/fonts/fonts.css';
import { createPinia } from 'pinia'; // main.js
import { createHead } from '@vueuse/head';
import VueGoogleMaps from '@fawmi/vue-google-maps';

// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faCalendarDays, faTimes, faChevronDown, faEnvelope, faPhone, faArrowUp, faChevronUp } from '@fortawesome/free-solid-svg-icons';
library.add(faChevronDown, faChevronUp, faEnvelope, faPhone, faCheck, faArrowUp, faTimes, faCalendarDays);

// Global components
import AnimatedComponent from '@/components/AnimatedComponent/AnimatedComponent.vue';
import BlockSection from '@/components/BlockSection/BlockSection.vue';
import DynamicButton from '@/components/DynamicButton/DynamicButton.vue';
import ItemImage from '@/components/Items/ItemImage.vue';
import ItemVideo from '@/components/Items/ItemVideo.vue';
import ItemRichText from '@/components/Items/ItemRichText.vue';
import ItemLink from '@/components/Items/ItemLink.vue';

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(head);
app.use(createPinia());

app.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyCJnA388xnqZaBEkFtcqpsoAmkKINClMEQ',
	},
});

app.component('FontAwesomeIcon', FontAwesomeIcon)
	.component('AnimatedComponent', AnimatedComponent)
	.component('ItemImage', ItemImage)
	.component('ItemVideo', ItemVideo)
	.component('ItemRichText', ItemRichText)
	.component('ItemLink', ItemLink)
	.component('DynamicButton', DynamicButton)
	.component('BlockSection', BlockSection);

app.mount('#app');
