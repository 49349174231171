import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf3fe65c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "target" }
const _hoisted_2 = {
  key: 0,
  class: "animated-component"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: $props.animationType }, {
      default: _withCtx(() => [
        ($setup.animate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["name"])
  ], 512))
}