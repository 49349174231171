
import axios from 'axios';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { computed, ref } from 'vue';
import StandardPage from '@/pages/StandardPage.vue';
import ErrorPage from '@/pages/ErrorPage.vue';
import type { Page } from '@/models/page';
import { store } from '@/store';
import router from '@/router';

export default {
	setup() {
		const showLoader = ref(true);
		const route = useRoute();
		const path = computed(() => route.path);
		const data = ref<Page>();
		const description = ref<string | undefined>('Capo är en webbyrå med kontor i Göteborg och Uddevalla.');
		const title = ref<string | undefined>('Capo.se');
		const pageStore = store();
		const error = ref(false);

		const getPage = async (path: string) => {
			try {
				const prefix = process.env.NODE_ENV !== 'production' ? '/api/' : '';
				const json = await axios(`${prefix + path}?jsondata=1`);

				error.value = false;
				data.value = json.data;
				title.value = data.value?.metaTitle;
				description.value = data.value?.metaDescription;
				showLoader.value = false;
				pageStore.isLoading = false;

				if (data.value?.isStartPage === 'True') pageStore.setIsStartPage(true);
				else pageStore.setIsStartPage(false);
			} catch (e) {
				console.log('error: ', e);
				error.value = true;
				pageStore.isLoading = false;
			}
		};

		if (route.fullPath.includes('&Purge=True')) {
			const query = Object.assign({}, route.query);
			delete query.Purge;
			router.replace({ query });
		}

		getPage(path.value);

		useHead({
			title,
			meta: [{ name: 'description', content: description.value }],
		});

		onBeforeRouteUpdate(async (to, from) => {
			if (to.path !== from.path) {
				await getPage(to.path);
			}
		});

		return {
			data,
			error,
			showLoader,
		};
	},

	components: {
		StandardPage,
		ErrorPage,
	},
};
