
import BlockSection from '../BlockSection/BlockSection.vue';
export default {
	name: 'TwoBoxes',
	components: { BlockSection },

	props: {
		content: {
			type: Object,
			required: true,
		},
	},
};
