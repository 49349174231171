import { defineStore } from 'pinia';
import axios from 'axios';
import { Category } from '@/models/categories';
import { ref } from 'vue';
import { Navigation } from '@/models/navigation';
import { Footer } from '@/models/footer';

export const store = defineStore('store', () => {
	const navigation = ref<Navigation>();
	const footer = ref<Footer>();
	const categories = ref<Category[]>([]);
	const isLoading = ref(true);

	const getCategories = async () => {
		try {
			const url = process.env.NODE_ENV !== 'production' ? '/api/categories' : '/categories';
			const json = await axios(`${url}?jsondata=1`);
			categories.value = json.data.page;
		} catch (e) {
			console.log('error: ', e);
		}
	};

	const getNavigation = async () => {
		try {
			const url = process.env.NODE_ENV !== 'production' ? '/api/getnavigation' : '/getnavigation';
			const json = await axios(url);
			navigation.value = json.data;
		} catch (e) {
			console.log('error: ', e);
		}
	};

	const getFooter = async () => {
		try {
			const url = process.env.NODE_ENV !== 'production' ? '/api/getfooter' : '/getfooter';
			const json = await axios(url);
			footer.value = json.data;
		} catch (e) {
			console.log('error: ', e);
		}
	};

	getNavigation();
	getFooter();
	getCategories();

	const isStartPage = ref<boolean>(false);
	const setIsStartPage = (value: boolean) => {
		isStartPage.value = value;
	};

	return {
		getCategories,
		getNavigation,
		navigation,
		categories,
		footer,
		setIsStartPage,
		isStartPage,
		isLoading,
	};
});
