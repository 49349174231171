import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5755a65a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid grid-2 gap-6" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockSection = _resolveComponent("BlockSection")!

  return (_openBlock(), _createBlock(_component_BlockSection, { class: "two-boxes" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "flex-y-center",
          style: _normalizeStyle({ 'background-color': $props.content.backgroundColorOne })
        }, [
          _createElementVNode("div", {
            class: "rich-text",
            innerHTML: $props.content.textOne
          }, null, 8, _hoisted_2)
        ], 4),
        _createElementVNode("div", {
          class: "",
          style: _normalizeStyle({ 'background-color': $props.content.backgroundColorTwo })
        }, [
          _createElementVNode("div", {
            class: "rich-text",
            innerHTML: $props.content.textTwo
          }, null, 8, _hoisted_3)
        ], 4)
      ])
    ]),
    _: 1
  }))
}